<template>
  <div class="caseFailure-caseImg">
    <GlobalInfoBar title="旧机示例图" content="示例图片将用于门店端报价时，上传旧机照片提供展示说明（请务必按要求上传）" />
    <div class="case">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_hsfl_.png" alt />
        <div class="case-title_max">回收分类</div>
        <span
          style="color:#ff687b;font-size: 12px;">可以开机/不能开机分别最多可上架10张示例图，最少上传1张示例图；图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px</span>
      </div>
      <div class="class_type">
        <el-radio-group v-model="calssSelect" v-if="classList.length != 0">
          <el-radio-button v-for="item in classList" :key="item.id" :label="item.id">{{ item.name }}</el-radio-button>
        </el-radio-group>
        <span v-else class="tip">暂无分类数据，请先添加，立即前往
          <el-link type="primary" href="/classification/add">分类管理</el-link>
          添加</span>
      </div>
      <div class="button_top">
        <div class="radiobtn">
          <div class="faultBtn">
            <div :class="position === '02' ? 'active' : 'none'" @click="position = '02'">
              苹果品牌
            </div>
            <div :class="position === '01' ? 'active' : 'none'" @click="position = '01'">
              非苹果品牌
            </div>
          </div>
        </div>
        <div class="flex flex-jb flex1" style="margin-left: 30px">
          <div class="flex">
            <div style="color: #666666;font-size:14px">示例图筛选：</div>
            <el-select v-model="searchValue" size="small" placeholder="请选择查询" @change="searchValueChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-button type="primary" icon="el-icon-plus"
              @click="$refs['addSampleImageDia'].open('00')">添加示例图</el-button>
          </div>
        </div>
      </div>
      <div style="padding: 0 20px">
        <GlobalTable :maxHeight="600" class="sample-image" ref="GlobalTable" v-loading="tableLoading"
          :columns="tableColumns" :data="tableData" :isPagination="false">
          <el-table-column label="示例图" slot="sampleImage" align="center">
            <template slot-scope="{ row }">
              <img :src="row.sampleImage" style="width: 70px;height:70px" alt="" @click="seeSampleImage(row)" />
            </template>
          </el-table-column>
          <el-table-column label="可以开机上架" slot="startingUpStow" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.startingUpStow" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => switchChange(e, row, '01')">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="不能开机上架" slot="noStartingUpStow" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.noStartingUpStow" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => switchChange(e, row, '02')">
              </el-switch>
            </template>
          </el-table-column>
          <!-- 新增 全新机 -->
          <el-table-column label="全新机" slot="fireNew" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.fireNew" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => switchChange(e, row, '04')">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="功能机/老年机上架" slot="elderlyMachineStow" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.elderlyMachineStow" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => switchChange(e, row, '03')">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="默认排序" slot="sort" align="center">
            <template slot-scope="{ row }">
              <el-input-number v-model="row.sort" @change="handleChange(row)" size="mini" :min="1"
                label="排序"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="设置机型" slot="machineConfig" align="center">
            <template slot-scope="{ row }">
              <p v-if="row.machineConfig == '00'">全部机型</p>
              <p v-else class="link primary" @click="modelConfigNext(row)">指定{{ row.machineNum }}个机型</p>
              <p class="link danger" @click="$refs['modelConfigDia'].open(row)">修改配置</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button size="mini" type="warning" round
                @click="$refs['addSampleImageDia'].open('00', row)">编辑</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
      <!-- 手机分类-非苹果品牌，新增补充示例图 -->
      <div class="case-title" style="margin-top:30px">
        <img class="case-title_icon" src="@/assets/images/aisi.png" alt />
        <div class="case-title_max">补充细节图（仅正常开机情况显示）</div>
      </div>
      <div style="padding: 0 20px">
        <div class="tab-box">
          <el-tabs :value="groupId" @tab-click="groupChange">
            <el-tab-pane v-for="item in personalConfigList" :key="item.groupId" :label="item.groupName"
              :name="item.groupId + ''" />
          </el-tabs>
          <el-link type="warning" :underline="false" class="add-btn"
            @click="$refs['addPersonalConfigDia'].open()">+添加个性配置</el-link>
        </div>
      </div>
      <div v-if="groupId" style="padding: 0 20px">
        <div class="add-spesial">
          <div class="title-base">
            设置机型
            <div class="model-num-box">已设置{{ sampleImageSpecialData.num }}个机型 <span class="link primary"
                @click="manageModel">管理机型</span></div>
          </div>
          <div>
            <el-button type="primary" icon="el-icon-plus"
              @click="$refs['addSampleImageDia'].open('01')">添加示例图</el-button>
          </div>
        </div>
        <GlobalTable :maxHeight="600" class="sample-image" ref="GlobalTable" v-loading="tableLoading2"
          :columns="tableColumns2" :data="sampleImageSpecialData.list" :isPagination="false">
          <el-table-column label="示例图" slot="sampleImage" align="center">
            <template slot-scope="{ row }">
              <img :src="row.sampleImage" style="width: 70px;height:70px" alt="" @click="seeSampleImage(row)" />
            </template>
          </el-table-column>
          <el-table-column label="是否必填" slot="isMust" align="center">
            <template slot-scope="{ row }">
              {{ row.isMust ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column label="是否上架" slot="isEnable" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.isEnable" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => switchChange(e, row, '01', true)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="默认排序" slot="sort" align="center">
            <template slot-scope="{ row }">
              <el-input-number v-model="row.sort" @change="handleChange(row, true)" size="mini" :min="1"
                label="排序"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button size="mini" type="warning" round
                @click="$refs['addSampleImageDia'].open('01', row)">编辑</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <!--新增旧机发货示例图-->
    <div class="case" style="margin-top: 20px;">
      <div class="case-title">
        <div class="case-title_max">旧机发货示例图</div>
      </div>
      <div class="class_type">
        <el-radio-group v-model="calssSelect1" v-if="classList1.length != 0" @input="calssSelect1change">
          <el-radio-button v-for="item in classList1" :key="item.id" :label="item.id">{{ item.name }}</el-radio-button>
        </el-radio-group>
        <span v-else class="tip">暂无分类数据，请先添加，立即前往
          <el-link type="primary" href="/classification/add">分类管理</el-link>
          添加</span>
      </div>
      <div class="button_top">
        <div class="radiobtn">
          <div class="faultBtn">
            <div :class="position1 === '02' ? 'active' : 'none'" @click="position1 = '02'">
              苹果品牌
            </div>
            <div :class="position1 === '01' ? 'active' : 'none'" @click="position1 = '01'">
              非苹果品牌
            </div>
          </div>
        </div>
        <div class="flex flex-jb flex1" style="margin-left: 30px">
          <div class="flex">
            <div style="color: #666666;font-size:14px">示例图筛选：</div>
            <el-select v-model="searchValue1" size="small" placeholder="请选择查询">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-button type="primary" icon="el-icon-plus" @click="openOldDialog">添加示例图</el-button>
          </div>
        </div>
      </div>
      <div style="padding: 0 20px">
        <GlobalTable :maxHeight="600" class="sample-image" ref="GlobalTable" v-loading="tableLoading1"
          :columns="tableColumns1" :data="tableData1" :isPagination="false">
          <el-table-column label="示例图" slot="sampleImage" align="center">
            <template slot-scope="{ row }">
              <img :src="row.sampleImage" style="width: 70px;height:70px" alt="" @click="seeSampleImage(row)" />
            </template>
          </el-table-column>
          <el-table-column label="可以开机上架" slot="startingUpStow" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.startingUpStow" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => openmachine(e, row, '01')">
              </el-switch>
            </template>
          </el-table-column>
          <!-- 新增 全新机 -->
          <el-table-column label="全新机" slot="fireNew" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.fireNew" active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                @change="(e) => opennew(e, row, '04')">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="默认排序" slot="sort" align="center">
            <template slot-scope="{ row }">
              <el-input-number v-model="row.sort" @change="handleChange1(row)" size="mini" :min="1"
                label="排序"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="设置机型" slot="machineConfig" align="center">
            <template slot-scope="{ row }">
              <p v-if="row.machineConfig == '00'">全部机型</p>
              <p v-else class="link primary" @click="modelConfigNext1(row)">指定{{ row.machineNum }}个机型</p>
              <p class="link danger" @click="$refs['modelConfigDia1'].open(row, calssSelect1)">修改配置</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button size="mini" type="warning" round @click="edit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <GlobalDialog :title="dialogTitle" :show="dialogVisible" :width="dialogWidth" @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData" @onClose="onClose">
      <div style="font-size:16px;color:#666666;">
        删除示例图，将影响门店端显示，确认要执行操作吗？
      </div>
    </GlobalDialog>
    <!--弹框-->
    <el-dialog class="set-img" :title="imageDialogTitle" :visible.sync="imageDialogVisible"
      :close-on-click-modal="false" width="500px" @closed="imageHandleClose">
      <div>
        <div class="command" style="margin-bottom: 10px;font-size:14px;color:#333333">
          <div style="width:110px;">示例图名称：</div>
          <el-input v-model.trim="imageName" maxlength="10" placeholder="请输入示例图名称" type="text" size="small" />
        </div>

        <div v-if="isSpecial" class="command" style="margin-bottom: 10px;font-size:14px;color:#333333">
          <div style="width:110px;">是否必填：</div>
          <el-radio-group v-model="isMust">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div style="margin-bottom: 10px;font-size:14px;color:#333333">
          示例图图片<span style="color:#ff687b;font-size: 12px;">(提示：图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px)</span>
        </div>
        <div class="demo-image__preview">
          <ElImgUpload key="sampleImage" :isBigImg="true" :exist-image="sampleImage"
            @handleChange="sampleHandleChange" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="imageDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="btnLoading" @click.native="imageHandleSure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="putDialogTitle" :visible.sync="putDialogShow" :close-on-click-modal="false" width="550px"
      @closed="putDialogClosed">
      <div class="cance-tip">
        <!-- <div>说明：{{putDialogTitle=='上架提示'?'上架后门店默认商家该示例图，门店可通过后台关闭使用':'下架后，员工下单小程序和门店示例图配置默认不显示该示例图'}}</div> -->
        <div class="tip-sure">是否确认{{ putDialogTitle == '上架提示' ? '上架' : '下架' }}该示例图 ？</div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="putDialogShow = false">取消</el-button>
        <el-button type="primary" :loading="putBtnLoading" @click.native="putDialogSubmit">确定</el-button>
      </span>
    </el-dialog>

    <el-image-viewer v-if="showViewer" :on-close="showViewer = false" :url-list="imgList" />
    <ModelConfigDia ref="modelConfigDia" @confirm="modelConfigNext" />
    <ModelConfigDia1 ref="modelConfigDia1" @confirm1="modelConfigNext1" />
    <!-- 示例图选择机型 -->
    <ModelSelectDia ref="modelSelectDia" :ids="machineIdList" :machineTypeId="calssSelect" :position="position"
      @confirm="modelSelectConfirm" />
    <!--旧机发货示例 -->
    <ModelSelectDia1 ref="modelSelectDia1" :ids="machineIdList1" :machineTypeId="calssSelect1" :position="position1"
      @confirm="modelSelectConfirm1" />
    <!-- 补充细节图选择机型 -->
    <ModelSelectDia ref="specialModelSelectDia" :ids="machineIdList" :machineTypeId="calssSelect" :position="position"
      filter="00" @confirm="specialModelSelectConfirm" />
    <AddPersonalConfigDia ref="addPersonalConfigDia" :position="position" :machineTypeId="calssSelect"
      @confirm="getPersonalConfig()" />
    <AddSampleImageDia ref="addSampleImageDia" :position="position" :machineTypeId="calssSelect" :groupId="groupId"
      @confirm="addSampleConfirm" />
    <!--新增旧机示例弹框-->
    <el-dialog :title="title" :visible.sync="addOldDialgVisible" :close-on-click-modal="false" width="600px"
      @close="closeAddImgDialog">
      <el-form ref="formRef" :model="formData" :rules="formRule" label-width="110px">
        <el-form-item label="示例图名称：" prop="imageName">
          <el-input v-model.trim="formData.imageName" maxlength="10" placeholder="请输入示例图名称" type="text" size="small" />
        </el-form-item>
        <el-form-item label="示例图图片" prop="sampleImage">
          <!-- @handleChange="sampleHandleChange" -->
          <ElImgUpload :isBigImg="true" :exist-image="formData.sampleImage"
            placeTxt="提示：图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px" @handleChange="sampleHandleChange1" />
        </el-form-item>
        <el-form-item>
          <p>拍照提示(非必填):</p>
          <el-input type="textarea" v-model="formData.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="closeAddImgDialog">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import environments from "@/config/url";
import { encryptByDES } from "@/utils/3DES";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import ElImgUpload from "../../components/global/components/elements/upload-element";
import ModelConfigDia from './dialog/ModelConfigDia';
import ModelConfigDia1 from './dialog/ModelConfigDia1.vue'
import ModelSelectDia from './dialog/CaseImgModelSelectDia.vue';
import AddPersonalConfigDia from './dialog/AddPersonalConfigDia.vue';
import AddSampleImageDia from './dialog/AddSampleImageDia.vue';
import ModelSelectDia1 from './dialog/ModelSelectDia1.vue';
import { _http } from "../../utils/request/exampleImg";
export default {
  name: "oldBrand-list",
  components: { ElImageViewer, ElImgUpload, ModelConfigDia, ModelSelectDia, AddPersonalConfigDia, AddSampleImageDia, ModelConfigDia1, ModelSelectDia1 },
  data() {
    return {
      // 补充示例图数据
      isSpecial: false,//标记
      tableColumns2: [
        { label: "示例图名称", prop: "imageName" },
        { slotName: "sampleImage" },
        // { slotName: "notFoldableCurvedScreen" },
        // { slotName: "foldableScreen" },
        // { slotName: "curvedScreen" },
        { slotName: "isMust" },
        { slotName: "isEnable" },
        { slotName: "sort" },
        { slotName: "operation" },
      ],
      tableLoading2: false,
      // androidShowUpload:true,
      // iphoneShowUpload:true,
      uploadUrl: environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl,
      imageUrl: "",
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      position: "02",
      position1: "02",//(新增旧机)
      dialogVisible: false,
      dialogTitle: "",
      dialogWidth: 380,
      deletedId: "",
      arrAisi: [],
      calssSelect: "",
      calssSelect1: "",//机型品牌单项(新增旧机)
      classList: [],
      classList1: [],//机型品牌列表(新增旧机)
      isAisi: true,
      searchValue: "",
      searchValue1: "",////(新增旧机)
      command: "",
      rowId: "",
      imageDialogTitle: "",
      putDialogTitle: "",
      putDialogShow: false,
      imageDialogVisible: false,
      tableLoading: false,
      tableLoading1: false,//旧机loading
      btnLoading: false,
      putBtnLoading: false,
      tableData: [],
      tableData1: [],//
      tableColumns: [
        { label: "示例图名称", prop: "imageName" },
        { slotName: "sampleImage" },
        { slotName: "startingUpStow" },
        { slotName: "noStartingUpStow" },
        { slotName: "fireNew" },//全新机
        { slotName: "elderlyMachineStow" },
        { slotName: "sort" },
        { slotName: "machineConfig" },
        { slotName: "operation" },
      ],
      tableColumns1: [
        { label: "示例图名称", prop: "imageName" },
        { slotName: "sampleImage" },
        { slotName: "fireNew" },//全新机
        { slotName: "startingUpStow" },
        { slotName: "noStartingUpStow" },

        { slotName: "elderlyMachineStow" },
        { slotName: "sort" },
        { slotName: "machineConfig" },
        { slotName: "operation" },
      ],
      imgList: [],
      showViewer: false,

      sampleImage: "",
      imageName: "",
      isMust: '',

      options: [{
        value: '',
        label: '全部'
      }, {
        value: '01',
        label: '可以开机'
      }, {
        value: '02',
        label: '不能开机'
      }, {
        value: '03',
        label: '功能机/老年机'
      }, {
        value: '04',
        label: '全新机'
      }],
      options1: [{
        value: '',
        label: '全部'
      }, {
        value: '00',
        label: '可以开机'
      }, {
        value: '01',
        label: '全新机'
      }],
      // 配置机型
      modelConfigFormData: {},
      machineIdList: [],
      groupId: undefined,
      personalConfigList: [],
      sampleImageSpecialData: {
        list: [],
        machineIdList: [],
        num: 0
      },
      addOldDialgVisible: false,//旧机弹框
      formData: {
        imageName: '',
        sampleImage: '',
        desc: ""
      },
      formRule: {
        imageName: [{ required: true, message: '请输入示例图名称', trigger: 'change' }],
        sampleImage: [{ required: true, message: '请上传图片', trigger: 'change' }],
      },
      title: "添加示例图",
      editId: "",//编辑id
      machineIdList1: []
    };
  },
  created() {
    this.initData()
  },
  computed: {
  },
  watch: {
    // 苹果/非苹果切换
    position() {
      this.getListByMachineType();
      this.getPersonalConfig()
    },
    // 顶部分类切换
    calssSelect() {
      this.getListByMachineType();
      this.getPersonalConfig()
    },
    // 个性配置切换
    groupId() {
      this.getListByMachineTypeSpecial()
    },
    calssSelect1(val) {
      console.log(val)
      console.log(1)
      // this.getList()
    },
    position1() {
      console.log(2)
      this.getList()
    },
    searchValue1() {
      console.log(3)
      this.getList()
    }
  },
  methods: {
    async initData() {
      // 获取分类
      await this.getClassList();
      // 获取个性配置列表
      await this.getPersonalConfig()
    },
    calssSelect1change(e) {
      this.calssSelect1 = e
      this.getList()
    },
    // 排序
    handleChange(row, isSpecial) {
      console.log(row);
      const params = {
        id: row.id,
        sort: row.sort,
      };
      // 补充示例图
      if (isSpecial) {
        _api.imageSortSpecial(params).then((res) => {
          if (res.code === 1) {
            this.getListByMachineTypeSpecial();
            this.$message.success(res.msg);
          }
        });
      } else {
        _api.imageSort(params).then((res) => {
          if (res.code === 1) {
            this.getListByMachineType();
            this.$message.success(res.msg);
          }
        });
      }
    },
    //新增旧机排序
    handleChange1(row) {
      const params = {
        id: row.id,
        sort: row.sort,
      };
      _http.editSort(params).then((res) => {
        if (res.code === 1) {
          this.getList();
          this.$message.success(res.msg);
        }
      });
    },
    //删除
    handleDelete(val) {
      console.log(val);
      this.dialogTitle = "删除提示";
      this.dialogVisible = true;
      this.deletedId = val.id;
    },
    //弹窗确定
    submitPopupData() {
      _api.imageDelete({ id: this.deletedId }).then((res) => {
        if (res.code === 1) {
          this.$message.success("删除成功");
          this.getListByMachineType();
        }
      });
      this.dialogVisible = false;
    },
    //弹窗取消
    resetPopupData() {
      this.dialogVisible = false;
    },
    onClose() {
      this.dialogVisible = false;
    },
    // 成功调用
    handleAvatarSuccessAisi(res) {
      this.imageUrl = res.fileUrl;
      console.log(this.imageUrl);
      const dto = {
        position: "03", //03爱思助手
        sampleImage: this.imageUrl,
        machineTypeId: this.calssSelect,
      };
      _api.ImageAdd(dto).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
          this.getListByMachineType();
        }
      });
    },
    // 上传前调用
    beforeAvatarUpload(file) {
      this.headers.TOKEN = encryptByDES(
        encryptByDES(
          new Date().getTime().toString(),
          "63e42c2444e94c1ebca21d30d2aa39a5"
        ),
        localStorage.getItem("token") || ""
      );
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或者PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    groupChange(e) {
      this.groupId = e.name
    },
    addSampleConfirm(type) {
      if (type == '00') {
        this.getListByMachineType()
      } else {
        this.getListByMachineTypeSpecial()
      }
    },
    manageModel() {
      this.machineIdList = JSON.parse(JSON.stringify(this.sampleImageSpecialData.machineIdList))
      this.$nextTick(() => {
        this.$refs['specialModelSelectDia'].open()
      })
    },

    // 获取分类集合
    async getClassList() {
      const res = await _api.selectList()
      const list = res.data || []
      this.classList = res.data || [];
      this.classList1 = res.data || [];
      if (list.length) {
        this.calssSelect = list[0].id;
        this.calssSelect1 = list[0].id;
        this.getList()
      }
    },
    // 获取示例图列表
    getListByMachineType() {
      let params = {
        machineTypeId: this.calssSelect,
        position: this.position,
      };
      if (this.searchValue) {
        switch (this.searchValue) {
          case '01':
            params.startingUpStow = true
            break;
          case '02':
            params.noStartingUpStow = true
            break;
          case '03':
            params.elderlyMachineStow = true
            break;
          case '04':
            params.fireNew = true
            break;
          default:
            break;
        }
      }
      this.tableLoading = true;
      _api.sampleImage(params).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.tableData = res.data.sampleImages;
          this.arrAisi = res.data.elsieImages;
          this.tableLoading = false;
        }
      });
    },
    // 获取个性配置列表（补充细节图）
    async getPersonalConfig() {
      const res = await _api.getPersonalConfig({ position: this.position, typeId: this.calssSelect })
      this.personalConfigList = res.data
      if (this.personalConfigList.length) {
        this.groupId = this.personalConfigList[0].groupId + ''
      } else {
        this.groupId = ''
      }
    },
    // 获取示例图片列表【补充示例图】
    getListByMachineTypeSpecial() {
      if (!this.groupId) return
      let params = {
        machineTypeId: this.calssSelect,
        position: this.position,
        groupId: this.groupId
      };
      this.tableLoading2 = true;
      _api.sampleImageSpecial(params).then((res) => {
        this.sampleImageSpecialData = res.data
        this.tableLoading2 = false;
      });
    },


    searchValueChange() {
      this.getListByMachineType()
    },
    // 苹果/非苹果切换查询
    // btnClick(type) {
    //   this.position = type;
    //   // this.getListByMachineType();
    //   // if (this.position === '01') {
    //     // this.getListByMachineTypeSpecial()
    //   // }
    // },


    // 查看示例图大图
    seeSampleImage(row) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(row.sampleImage);
    },
    // 设置示例图显示配置
    switchChange(e, row, type, isSpecial) {
      this.isSpecial = isSpecial
      this.saveType = type
      this.putDialogTitle = e ? "上架提示" : '下架提示'
      this.rowId = row.id
      // 补充示例图
      if (this.isSpecial) {
        if (type == '01') {
          this.isEnable = e
        }
        this.putDialogShow = true
      } else {
        if (type == '01') {
          this.startingUpStow = e
        }
        if (type == '02') {
          this.noStartingUpStow = e
        }
        if (type == '03') {
          this.elderlyMachineStow = e
        }
        if (type == '04') {
          this.fireNew = e
        }
        this.putDialogShow = true
      }
      this.putBtnLoading = false
    },
    // 设置示例图弹框关闭
    imageHandleClose() {
      this.sampleImage = ""
      this.imageName = ""
      this.imageDialogVisible = false;
    },
    // 圖片更換
    sampleHandleChange(e) {
      this.sampleImage = e;
    },
    // 圖片更換
    sampleHandleChange1(e) {
      this.formData.sampleImage = e;
    },
    // 添加/编辑示例图
    setSampleImage(type, row) {
      this.isSpecial = false
      this.imageDialogVisible = true;
      this.rowId = ""
      if (type == "add") {
        this.imageDialogTitle = "添加示例图";
      } else {
        this.rowId = row.id
        this.imageDialogTitle = "编辑示例图";
        this.sampleImage = row.sampleImage;
        this.imageName = row.imageName;
      }
    },
    // 示例图保存确定
    imageHandleSure() {
      if (!this.imageName) {
        this.$message.error("请输入示例图名称");
        return;
      }
      if (!this.sampleImage) {
        this.$message.error("示例图片不能为空");
        return;
      }
      const dto = {
        id: this.rowId,
        position: this.position,
        machineTypeId: this.calssSelect,
        imageName: this.imageName,
        sampleImage: this.sampleImage,
      };
      this.btnLoading = true;
      // 补充示例图
      if (this.isSpecial) {
        _api
          .ImageAddSpecial({ ...dto, isMust: this.isMust })
          .then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "操作成功");
              this.getListByMachineTypeSpecial();
              this.imageDialogVisible = false;
            }
            this.btnLoading = false;
            // this.isSpecial=false
          })
          .catch(() => {
            this.btnLoading = false;
            // this.isSpecial=false
          });
      } else {
        _api
          .ImageAdd(dto)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "操作成功");
              this.getListByMachineType();
              this.imageDialogVisible = false;
            }
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    // 添加/编辑示例图[补充示例图]
    setSampleImageSpecial(type, row) {
      this.isSpecial = true
      this.imageDialogVisible = true;
      this.rowId = ""
      if (type == "add") {
        this.imageDialogTitle = "添加示例图";
      } else {
        this.rowId = row.id
        this.imageDialogTitle = "编辑示例图";
        this.sampleImage = row.sampleImage;
        this.imageName = row.imageName;
      }
    },
    // 上/下架弹框关闭回调
    putDialogClosed() {
      this.command = ""
      if (this.isSpecial) {
        this.getListByMachineTypeSpecial();
      } else {
        this.getListByMachineType();
      }
    },
    // 上/下架弹框确定
    putDialogSubmit() {
      let params = {
        saveType: this.saveType,
        command: this.command,
        id: this.rowId
      }
      // 补充示例图
      if (this.isSpecial) {
        // if (this.saveType == '01') {
        //   params.isEnable = this.isEnable
        // }
        // if (this.saveType == '02') {
        //   params.curvedScreen = this.curvedScreen
        // }
        // if (this.saveType == '03') {
        //   params.notFoldableCurvedScreen = this.notFoldableCurvedScreen
        // }
        this.putBtnLoading = true
        _api.enableOrDisable({
          sampleId: params.id,
          isEnable: this.isEnable,
          command: this.command,
        }).then(res => {
          this.$message.success(res.msg || '操作成功')
          this.putDialogShow = false
        }).catch(() => {
          this.putBtnLoading = false
        })
      } else {
        if (this.saveType == '01') {
          params.startingUpStow = this.startingUpStow
        }
        if (this.saveType == '02') {
          params.noStartingUpStow = this.noStartingUpStow
        }
        if (this.saveType == '03') {
          params.elderlyMachineStow = this.elderlyMachineStow
        }
        if (this.saveType == '04') {
          params.fireNew = this.fireNew
        }
        this.putBtnLoading = true
        _api.setImageShowConfig(params).then(res => {
          if (res.code == 1) {
            this.$message.success(res.msg || '操作成功')
            this.putDialogShow = false
          }
          this.putBtnLoading = false
        }).catch(() => {
          this.putBtnLoading = false
        })
      }
    },
    modelConfigNext({ id, machineConfig }) {
      this.modelConfigFormData = { id, machineConfig }
      if (machineConfig == '00') {
        this.modelSelectConfirm()
      } else {
        _api.getMachineConfig({ id }).then(res => {
          this.machineIdList = res.data.machineIdList
          this.$nextTick(() => {
            this.$refs['modelSelectDia'].open()
          })
        })
      }
    },
    modelConfigNext1(row) {
      console.log(row, 999)
      // this.modelConfigFormData = { id, machineConfig }
      if (row.machineConfig == '00') {
        _http.saveSet({
          sampleId: row.id, machineConfig: row.machineConfig
        }).then(res => {
          if (res.code == 1) {
            this.$message.success("修改成功");
            this.getList()
          }
        })
      } else {
        _http.dispatchGetMachineDeliverGood({ id: row.id }).then(res => {
          this.machineIdList1 = res.data.machineIdList
          this.$nextTick(() => {
            console.log(this, 9999)
            this.$refs.modelSelectDia1.open(row.sampleId || row.id)
          })
        })
      }
    },
    modelSelectConfirm(machineIdList) {
      _api.saveMachineConfig({
        ...this.modelConfigFormData,
        isIos: this.position == '02' ? '01' : '02',
        machineIdList
      }).then(res => {
        this.$message.success("修改成功");
        this.getListByMachineType()
        this.$refs['modelSelectDia'].close()
      })
    },
    modelSelectConfirm1(machineIdList, id) {
      this.$refs['modelSelectDia1'].close()
      this.getList()
    },
    specialModelSelectConfirm(machineIdList) {
      _api.saveMachineSpecialConfig({
        groupId: this.groupId,
        isIos: this.position == '02' ? '01' : '02',
        machineIdList
      }).then(res => {
        this.$message.success("修改成功");
        this.getListByMachineTypeSpecial()
        this.$refs['specialModelSelectDia'].close()
      })
    },
    //打开添加旧机弹框示例图
    openOldDialog() {
      this.title = "添加示例图"
      this.addOldDialgVisible = true
      this.formData.desc = ""
      this.editId = ""
      this.formData.imageName = ""
      this.formData.sampleImage = ""
      this.$refs.formRef.resetFields();
    },
    //提交示例图
    confirm() {
      this.$refs.formRef.validate((valid) => {
        let params = {
          imageName: this.formData.imageName,
          position: this.position1,
          sampleImage: this.formData.sampleImage,
          machineTypeId: this.calssSelect1,
        }
        if (this.formData.desc) {
          params.tip = this.formData.desc
        }
        if (!this.formData.desc) {
          params.tip = ''
        }
        if (this.editId) {
          params.id = this.editId
        }
        if (valid) {
          _http.addImage(params).then(res => {
            if (res.code == 1) {
              this.editId ? this.$message.success("编辑示例图成功") : this.$message.success("添加示例图成功")
              this.addOldDialgVisible = false
              this.$refs.formRef.resetFields();
              this.getList()
            }
          }).catch(err => {
            this.addOldDialgVisible = false
            this.$refs.formRef.resetFields();
          })
        } else {
          return false;
        }
      });
    },
    //关闭对话弹框
    closeAddImgDialog() {
      this.addOldDialgVisible = false
      this.$refs.formRef.resetFields();
      this.formData.desc = ""
      this.editId = ""
    },
    //获取示例图列表
    getList() {
      this.tableLoading1 = true;
      let params = {
        machineTypeId: this.calssSelect1,
        position: this.position1,
        type: this.searchValue1
      }
      _http.getListDeliverGood(params).then(res => {
        if (res.code == 1) {
          console.log(res);
          this.tableData1 = res.data;
          this.tableLoading1 = false;
        }
      }).catch(err => {
        this.tableLoading1 = false;
      })
    },
    //修改配置
    editSet(row) {
      console.log(row, 9999)
    },
    //编辑
    edit(row) {
      this.editId = row.id
      this.addOldDialgVisible = true
      this.title = "编辑示例图"
      this.formData.imageName = row.imageName,
        this.formData.sampleImage = row.sampleImage,
        this.formData.desc = row.tip
    },
    //开机上价
    openmachine(e, row) {
      _http.openCloseSwitch({ isEnable: e, id: row.id, type: "00" }).then(res => {
        if (res.code == 1) {
          this.$message.success("操作成功")
          this.getList()
        }
      }).catch(err => {

      })
    },
    //全新
    opennew(e, row) {
      _http.openCloseSwitch({ isEnable: e, id: row.id, type: "01" }).then(res => {
        if (res.code == 1) {
          this.$message.success("操作成功")
          this.getList()
        }
      }).catch(err => {

      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.add-spesial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title-base {
  font-weight: 700;
  font-size: 16px;
  border-left: 8px solid #3399FF;
  padding: 0 10px;
  box-sizing: border-box;
  color: #000;
  display: flex;

  .model-num-box {
    margin-left: 20px;
    font-size: 14px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.flex1 {
  flex: 1;
}

.caseFailure-caseImg {
  /deep/.el-input__inner {
    padding: 0 8px;
  }
}

.button_top {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.faultBtn {
  width: 250px;
  height: 40px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;
  cursor: pointer;

  .active {
    text-align: center;
    color: white;
    width: 50%;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 38px;
    height: 38px;
    background: #0981ff;
    border-radius: 20px;
  }

  .none {
    text-align: center;
    color: #333;
    width: 50%;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 38px;
    height: 38px;
    border-radius: 20px;
  }
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.case {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  width: 100%;
  padding: 14px 0;

  .class_type {
    padding: 20px;

    .tip {
      font-size: 14px;
      color: #666666;
    }

    /deep/.el-radio-group {
      .el-radio-button {
        margin-right: 20px;

        .el-radio-button__inner {
          border-radius: 4px;
          border: 1px solid #0981ff;
        }
      }
    }
  }

  .case-title {
    padding: 0 20px;
    display: flex;
    align-items: center;

    .case-title_icon {
      width: 20px;
      height: 20px;
      margin-right: 3px;
    }

    .case-title_max {
      // width: 150px;
      // height: 17px;
      font-size: 18px;
      font-family: FZLanTingHei-B-GBK;
      font-weight: 400;
      color: #333333;
      line-height: 41px;
      margin-right: 10px;
    }

    .case-title_min {
      margin-left: 5px;
      width: 500px;
      height: 13px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff687b;
      line-height: 45px;
    }
  }

  .case-img {
    margin: 20px;
    height: 280px;
    background: #f5f6fa;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .upload_Add {
      width: 200px;

      // height: 200px;
      /deep/.el-upload--picture-card {
        margin: 0 30px;
      }
    }

    .upload_Class {
      position: relative;
      width: 200px;

      // height: 200px;
      .img:hover .mark {
        position: absolute;
        top: 0;
        left: 0;
        width: 148px;
        height: 148px;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }

      .img {
        position: relative;
        padding: 0;
        width: 148px;
        height: 148px;
        border-radius: 12px;
        margin: 0 auto;

        img {
          width: 148px;
          height: 148px;
        }

        .mark {
          display: none;
        }
      }

      .sort {
        width: 130px;
        height: 30px;
        margin: 10px auto;
      }

      .imageName {
        width: 130px;
        height: 30px;
        margin: 10px auto;
      }
    }
  }

  .sample-image {
    /deep/.el-switch__label * {
      line-height: 1;
      font-size: 12px;
      display: inline-block;
    }

    /deep/.el-switch__label {
      position: absolute;
      display: none;
      color: #fff !important;
      font-size: 12px !important;
    }

    /*打开时文字位置设置*/
    /deep/.el-switch__label--right {
      z-index: 1;
      right: 4px;
    }

    /*关闭时文字位置设置*/
    /deep/.el-switch__label--left {
      z-index: 1;
      left: 4px;
    }

    /*显示文字*/
    /deep/.el-switch__label.is-active {
      display: block;
    }

    /*开关宽度*/
    /deep/.el-switch .el-switch__core,
    /deep/.el-switch .el-switch__label {
      width: 50px !important;
    }
  }
}

.tab-box {
  display: flex;

  // justify-content: space-between;
  // align-items: center;
  .add-btn {
    margin-bottom: 14px;
    margin-left: 20px;
  }

  /deep/.el-tabs__nav-wrap::after {
    display: none !important;
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;

  &.primary {
    color: #0981ff;
  }

  &.danger {
    color: #ff687b;
  }
}

/deep/.el-form-item:nth-child(3) .el-form-item__content {
  margin-left: 0 !important;
}
</style>
