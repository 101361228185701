import request from './request'

export const _http = {
    //发货示例图列表
    getListDeliverGood(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/list-deliver-good',
            data:params
        })
    },
    //保存发货示例图图片
     addImage(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/save-deliver-good',
            data:params
        })
    },
    //保存机型配置
    saveSet(params){
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/save-machine-deliver-good',
            data:params
        })
    },
    //修改排序
    editSort(params){
        return request({
            method: 'GET',
            url: '/platform/pc/sampleImage/update-sort-deliver-good',
            params:params
        })
    },
        //打开关闭
    openCloseSwitch(params){
        return request({
                method: 'POST',
                url: '/platform/pc/sampleImage/oc',
                data:params
        })
    },
    //获取机型配置【发货示例图】
    dispatchGetMachineDeliverGood(params){
        return request({
                method: 'GET',
                url: '/platform/pc/sampleImage/get-machine-deliver-good',
                params:params
        })
    },
}