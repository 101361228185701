<template>
    <div>
      <el-dialog title="修改配置" :visible.sync="visible" :close-on-click-modal="false" width="600px" @close="close">
        <div style="margin-bottom: 20px">该功能项适用机型配置：</div>
        <el-radio-group v-model="form.machineConfig">
          <el-radio label="00">全部机型</el-radio>
          <el-radio label="01">指定机型</el-radio>
        </el-radio-group>
        <div style="color: red; margin-top: 20px">
          {{ form.machineConfig == '00' ? '切换到全部机型后，该功能将默认全部机型均使用' : '如需切换到指定机型，需设置完成机型才可切换' }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click.native="close">取消</el-button>
          <el-button type="primary" @click.native="confirm">{{form.machineConfig == '00' ? '确定' : '下一步' }}</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import _api from "@/utils/request";
  export default {
    name: "ModelConfigDia1",
    props: { },
    data() {
      return {
        visible: false,
        form: {
          id: '',
          machineConfig: '00'
        }
      };
    },
    methods: {
      open(row,machinid) {
        console.log(row,"row")
        const { id} = row
        if(this.form.machineConfig=="00"){
          this.form = { id:id, machineConfig:this.form.machineConfig }
        }
        this.visible = true
      },
      close() {
        this.visible = false
        this.form.id=""
        this.form.machineConfig="00"
      },
      confirm() {
        this.$emit('confirm1', JSON.parse(JSON.stringify(this.form)))
        this.visible = false
      }
    }
  };
  </script>